
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTooltip',
  components: { Pic },
  setup () {
    const showTip = shallowRef(false)
    const HandleTaggle = () => {
      showTip.value = !showTip.value
    }
    const HandleClose = () => {
      showTip.value = false
    }
    return {
      showTip,
      HandleTaggle,
      HandleClose,
    }
  },
})

