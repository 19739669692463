
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tooltip from '@/pages/interest/components/Tooltip.vue'
import ColorText from '@/components/ColorText.vue'
import { mul } from 'essential/tools/math'
import {
  readInterestInfo,
  readInterestList,
  InterestItem,
} from '@/pages/interest/interest.api'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import Scroller from 'common/providers/Scroller.vue'
import Money from '@/components.global/Money'

export default defineComponent({
  name: 'Interest',
  components: {
    PageWithHeader,
    Tooltip,
    ColorText,
    List,
    Holder,
    Scroller,
    Money,
  },
  setup () {
    const data = shallowRef()
    // 获取账户利息信息
    const getInterestPre = () => {
      readInterestInfo().then((res) => {
        res.interestRate = mul(res.interestRate, 100)
        data.value = res
      })
    }
    getInterestPre()

    // 获取利息历史列表
    const { loaded, refresh, loadMore } = useLoadMore<InterestItem,
      Array<InterestItem>>(readInterestList, 10, (resp) => resp)
    refresh()
    return {
      data,
      loaded,
      loadMore,
      refresh (done: () => void) {
        refresh().then(done)
      },
    }
  },
})
