import requestResult from '@/common/request/request'
export type InterestItem = {
    issue: string;
    balance: number;
    interest: number;
    cause: number;
}
export type InterestSchema = {
    currentBalance: number;
    interestRate: number;
    interest: number;
    interestCredit: number;
    detailModels: InterestItem[];
}

export const readInterestInfo = requestResult<InterestSchema>('/api/interest/info') // 利息预览

export const readInterestList = requestResult<InterestItem[]>('/api/interest/detail') // 利息详情--list
